@import "responsive";


@include respond-to(tv) {
	.s-about-nav {
		width: 25%; }
	.s-about-nav-logo {
		max-width: 226px; }
	.s-about-menu li a {
		font-size: 17px;
		max-width: 245px; }
	.s-about-menu {
		padding-top: 48px; }
	.s-about-menu li + li {
		margin-top: 10px; }
	.s-about-menu li.style1 {
		margin-top: 8px; }
	.s-about-menu li.last {
		margin-top: 20px;
		padding-top: 21px; }
	.s-about-nav-bottom {
		padding-bottom: 38px; }
	.s-about-main {
		width: 75%; }
	.s-about-main-contant {
		padding: 38px 60px 38px 83px; }
	.s-about-line {
		margin-left: 60px; }
	.s-about-title {
		font-size: 4.9vh;
		line-height: 1.1; }
	.s-about-descr {
		font-size: 2.07vh;
		line-height: 1.5; }
	.s-about-info {
		font-size: 3.07vh;
		line-height: 1.12; }
	.s-about-descr1 {
		font-size: 1.6vh;
		line-height: 1.48; }
	.s-about-subtitle {
		font-size: 1.42vh;
		line-height: 2; }
	.s-about-list {
		padding-bottom: 10px; }
	.s-about-list li {
		margin-bottom: 3vh; }
	.s-about-menu li {
		padding-right: 15px; }
	.s-about-main-contant {
		padding: 0; }
	.s-about-slider .item {
		padding: 38px 60px; }
	.s-about-list {
		padding: 50px 60px 38px; }
	.s-about-contacts {
		padding-bottom: 20px; }
	.s-about-subtitle .icon.icon1 {
		svg {
			width: 4.4vh;
			height: 4.4vh; } }
	.s-about-subtitle .icon.icon2 {
		svg {
			width: 4.4vh;
			height: 4.4vh; } }
	.s-about-subtitle .icon.icon3 {
		svg {
			width: 5.5vh;
			height: 4.4vh; } }
	.s-about-subtitle .icon.icon4 {
		svg {
			width: 4.4vh;
			height: 4.4vh; } }
	.s-about-subtitle .icon.icon5 {
		svg {
			width: 4.4vh;
			height: 4.4vh; } }
	.s-about-subtitle .icon.icon6 {
		svg {
			width: 5.6vh;
			height: 4.8vh; } }
	.s-about-subtitle .icon.icon7 {
		svg {
			width: 4.4vh;
			height: 4.4vh; } }
	.s-about-service {
		width: 356px; }
	.s-about-service-subtitle {
		font-size: 16px;
		line-height: 24px; }
	.s-about-service-descr1 {
		font-size: 14px;
		line-height: 20px;
		padding-top: 7px; }
	.s-about-service-item {
		height: 115px; }
	.s-about-service-title {
		font-size: 20px;
		line-height: 24px; }
	.s-about-service-info {
		padding: 45px 20px 62px 47px; }
	.s-about-service-descr {
		font-size: 14px;
		line-height: 20px;
		padding-top: 9px; }
	.s-about-product-cont {
		width: calc(100% - 356px); }
	.s-about-product-slide {
		height: 56vh; }
	.s-about-product-info {
		padding: 18px 19px; }
	.s-about-product-logo {
		max-width: 205px; }
	.s-about-product-title {
		font-size: 2.5vh;
		line-height: 1.2;
		padding-top: 23px; }
	.s-about-product-descr {
		font-size: 2vh;
		line-height: 1.5; }
	.s-about-product-btns {
		padding-top: 26px; }
	.s-about-product-button .s-about-btn {
		height: 43px;
		font-size: 16px;
		line-height: 43px; }
	.s-about-product-button {
		max-width: 239px; }
	.s-about-product-cont .slick-dots {
		bottom: 30px; }
	.s-about-product-inform {
		bottom: 38px;
		right: 40px;
		left: auto; } }
@include respond-to(desktop-wide1) {
	.s-about-nav {
		padding-left: 30px; }
	.s-about-menu {
		padding-top: 25px; }
	.s-about-nav-bottom {
		padding-bottom: 20px; }
	.s-about-item {
		padding-bottom: 15px; }
	.s-about-menu li a {
		font-size: 16px; }
	.s-about-info {
		font-size: 2.6vh; }
	.s-about-main-contant {
		padding: 0; }
	.s-about-line {
		margin-left: 30px; }
	.s-about-slider .item {
		padding: 30px 30px 60px; }
	.s-about-list {
		padding: 40px 30px 30px; }
	.s-about-contacts {
		padding-bottom: 0; }
	.s-about-item.last {
		padding-bottom: 15px; }
	.s-about-nav-logo {
		max-width: 195px; }
	.s-about-product-button {
		max-width: 215px; }
	.s-about-product-inform-text {
		font-size: 12px; }
	.s-about-product-inform {
		width: 150px;
		height: 150px; }
	.s-about-product-inform-icon {
		font-size: 43px;
		line-height: 5px; }
	.s-about-product-cont .slick-dots {
		bottom: 15px;
		padding-left: 0;
		justify-content: center; }
	.s-about-product-slide {
		height: 50vh; }
	.s-about-service-info {
		padding-left: 30px; } }
@include respond-to(desktop) {
	.s-about-contant {
		height: auto; }
	.s-about-nav {
		display: block;
		position: fixed;
		left: -100%;
		top: 0;
		width: 100%;
		padding-left: 0;
		z-index: 999;
		height: auto;
		overflow-y: auto;
		padding-top: 70px;
		transition: .3s all;
		padding: 79px 9px 27px;
		background: #313439;
		border: 1px solid #E6E6E6;
		box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
		border-radius: 12px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-right: 0;
		border-left: 0;
		&.active {
			left: 0; }
		.s-about-nav-logo {
			display: none; }
		.s-about-nav-bottom {
			display: none; } }
	.s-about-menu li a {
		font-size: 20px; }
	.s-about-main {
		width: 100%;
		padding: 0; }
	.s-about-main-contant {
		padding: 0; }
	.s-about-top {
		background: #313439;
		border: 1px solid #E6E6E6;
		box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
		border-radius: 12px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		padding: 44px 23px 48px; }
	.s-about-main .s-about-nav-logo {
		display: block;
		padding: 0; }
	.s-about-title {
		font-size: 40px;
		line-height: 45px;
		color: #fff;
		padding-top: 50px; }
	.s-about-descr {
		color: #fff;
		font-size: 18px;
		line-height: 26px;
		padding-top: 23px; }
	.s-about-info {
		font-size: 25px;
		line-height: 30px; }
	.s-about-list {
		padding: 24px 25px 0; }
	.s-about-list li {
		width: 33.3%;
		margin-bottom: 19px; }
	.s-about-list li:nth-last-child(3) {
		margin-bottom: 19px; }
	.s-about-list li:nth-last-child(2) {
		margin-bottom: 19px; }
	.s-about-item {
		padding-bottom: 0;
		border-bottom: 0;
		background: #FFFFFF;
		border: 1px solid #D8E4F5;
		box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
		border-radius: 3px;
		padding: 13px 17px 21px;
		&.info, &.last {
			border: 0;
			box-shadow: none;
			border-radius: 0;
			background-color: transparent;
			padding: 13px 0; } }
	.s-about-subtitle {
		font-size: 12px;
		line-height: 2; }
	.s-about-subtitle .icon.icon1 svg {
		width: 38px;
		height: 38px; }
	.s-about-subtitle .icon.icon2 svg {
		width: 38px;
		height: 38px; }
	.s-about-subtitle .icon.icon3 svg {
		width: 48px;
		height: 34px; }
	.s-about-subtitle .icon.icon4 svg {
		width: 38px;
		height: 38px; }
	.s-about-subtitle .icon.icon5 svg {
		width: 38px;
		height: 38px; }
	.s-about-subtitle .icon.icon6 svg {
		width: 45px;
		height: 41px; }
	.s-about-subtitle .icon.icon7 svg {
		width: 36px;
		height: 36px; }
	.s-about-descr1 {
		font-size: 14px;
		line-height: 20px; }
	.s-about-contacts {
		display: none; }
	.s-about-phone {
		display: none; }
	.s-about-contacts-text {
		display: none; }
	.s-about-contacts-button {
		display: none; }
	.s-footer {
		display: block;
		background: #f8f9fb;
		padding: 39px 15px;
		&-inner {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			align-content: stretch;
			flex-wrap: wrap;
			text-align: center;
			width: 100%; }
		&-phone {
			font-size: 26px;
			line-height: 32px;
			letter-spacing: 0.276923px;
			color: #000000;
			position: relative;
			text-align: left;
			padding-left: 64px;
			margin-left: -21px;
			font-family: Proxima Nova;
			font-style: normal;
			font-weight: normal;
			a {
				color: #000; }
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				background-image: url("../img/icon-phone.svg");
				width: 48px;
				height: 48px;
				display: block;
				background-size: cover; } }
		.s-about-soc {
			width: 100%;
			justify-content: center;
			padding-top: 56px;
			li {
				margin-right: 18px;
				&:last-child {
					margin-right: 0; }
				&.tw {
					display: none; }
				&.google {
					display: none; }
				a {
					&:hover {
						svg path {
							fill: #F1361D; } }
					svg {
						width: 30px;
						height: 30px;
						path {
							fill: #333333; } } } } }
		.s-about-copy {
			font-family: Proxima Nova;
			font-style: normal;
			font-weight: normal;
			font-size: 11px;
			line-height: 13px;
			text-align: center;
			color: #000000;
			padding-top: 38px;
			max-width: 100%;
			width: 100%; }
		&-list {
			width: 100%;
			text-align: center;
			padding-top: 9px;
			li {
				+ li {
					margin-top: 10px; }
				a {
					font-family: Proxima Nova;
					font-style: normal;
					font-weight: normal;
					font-size: 11px;
					line-height: 13px;
					text-align: center;
					color: #000000;
					border-bottom: 1px solid rgba(0,0,0,0.4);
					transition: .3s all;
					&:hover {
						border-bottom: 1px solid transparent; } } } } }
	.s-toggle {
		display: block; }
	.s-header {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex; }
	.s-about-product-cont {
		position: relative;
		right: auto;
		opacity: 1;
		visibility: visible;
		width: 100%;
		display: none; }
	.s-about-service {
		width: 100%;
		height: auto; }
	.s-about-main {
		&.inner {
			padding-top: 80px;
			height: auto;
			.s-about-main-contant {
				&.active {
					display: none !important; } } } }
	.s-about-service-title {
		font-size: 25px;
		line-height: 30px; }
	.s-about-catagory {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex; }
	.s-about-service-info {
		padding: 0 24px 0; }
	.s-about-service-title {
		padding-top: 31px; }
	.s-about-service-descr {
		padding-top: 16px; }
	.s-about-service-list {
		margin-top: 41px; }
	.s-about-service-item {
		padding: 25px; }
	.s-about-main-contant.nav + .s-about-main-contant.nav {
		margin-top: 40px; }
	.s-about-main-contant.nav {
		height: auto; }
	.s-about-product-cont {
		height: auto; }
	.s-about-product-slide {
		height: 261px; }
	.s-about-product-inform {
		right: 26px;
		bottom: 23px;
		width: 139px;
		height: 139px;
		padding: 29px 5px; }
	.s-about-product-inform-icon {
		font-size: 56px; }
	.s-about-product-inform-text {
		font-size: 12px;
		line-height: 14px;
		padding-top: 28px; }
	.s-about-product-info {
		padding: 23px 25px 27px; }
	.s-about-product-logo {
		max-width: 100%; }
	.s-about-product-title {
		font-size: 18px;
		line-height: 22px;
		padding-top: 33px; }
	.s-about-product-descr {
		font-size: 14px;
		line-height: 21px; }
	.s-about-product-btns {
		padding-top: 31px; }
	.s-about-product-cont {
		top: auto; }
	.s-about-slider {
		display: none; }
	.s-about-mobile {
		display: block; } }
@include respond-to(tablet-large) {
	.s-about-list li {
		width: 50%; } }
@include respond-to(tablet);



@include respond-to(mobile) {
	.s-about-list li {
		width: 100%; }
	.s-about-list li:nth-last-child(2) {
		margin-bottom: 0; }
	.s-about-product-button {
		max-width: 100%;
		margin-right: 0; }
	.s-about-product-button .s-about-btn {
		height: 50px;
		font-size: 16px;
		line-height: 50px; }
	.s-about-product-button + .s-about-product-button {
		margin-top: 28px; } }
@include respond-to(mobile1);


@include respond-to(mobile2);

